<template>
  <!-- Add Pupil Modal -->
  <div id="addPupilsModal" class="modal is-active" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 id="myModalLabel" class="modal-title">
            Add {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}
          </h4>
          <p>
            Region: <span class="tag">{{ localeInfo() }}</span> <router-link v-if="$store.getters.isAdmin" to="/school" class="button is-small is-pulled-right">
              EDIT SCHOOL
            </router-link>
          </p>
        </div>
        <div class="tabs">
          <ul class="is-marginless">
            <li v-if="group" id="tabExisting" class="is-active">
              <a href="#" @click.prevent="showExisting">Existing {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}</a>
            </li>
            <li id="tabSingle" :class="{'is-active':(group === null)}">
              <a href="#" @click.prevent="showSingle">Add {{ $store.state.user.locale === 'en_GB' ? 'Pupil' : 'Student' }}</a>
            </li>
            <li id="tabUpload">
              <a href="#" @click.prevent="showUpload">Upload</a>
            </li>
          </ul>
        </div>
        <div class="modal-body">
          <div id="addExisting">
            <div style="margin-top:10px;">
              <b-field>
                <b-input
                  v-model="searchPhrase"
                  placeholder="Search..."
                  type="search"
                  icon-pack="fas"
                  icon="search"
                  @input="searchDidChange"
                />
              </b-field>
            </div>
            <b-table
              v-if="pupilData"
              :data.sync="filteredPupilData"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="Name" item-width="10px">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="username" label="Username">
                  {{ props.row.username }}
                </b-table-column>
                <b-table-column field="groups" label="Groups">
                  {{ props.row.groups }}
                </b-table-column>
                <b-table-column field="actions" label="">
                  <div class="btn-group is-pulled-right">
                    <button v-if="!pupilHasGroup(props.row)" tag="button" type="button" class="btn button is-link" @click.prevent="addExistingToGroup(props.row)">
                      Add
                    </button>
                    <div v-else>
                      <i class="fa fa-check" />
                    </div>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon
                        custom-class="far"
                        pack="fa"
                        icon="frown"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
          <div id="addSingle">
            <form class="ui form loginForm form-horizontal" style="width:100%" autocomplete="off" @submit.prevent="beforeAddPupil">
              <input style="display:none"><input type="password" style="display:none">
              <b-field label="Name*">
                <b-input v-model="name" size="is-medium" placeholder="Name or pseudonym" required />
              </b-field>
              <p>
                <button class="btn button is-link" @click.prevent="toggleExtraAddPupil">
                  More...
                </button>
              </p><p /></p><div v-if="showExtra" id="optionalPanel">
                <b-field
                  label="Username"
                  :type="{'is-success': usernameAvailable, 'is-danger': !usernameAvailable && username}"
                  :message="{'Username not available.': !usernameAvailable && username}"
                >
                  <b-input v-model="username" size="is-medium" placeholder="Choose a username (must be unique within EdShed)" @input="checkUsername" />
                </b-field>
                <b-field label="Display Name">
                  <b-input v-model="displayname" size="is-medium" placeholder="Display name (leave blank for pupil's name)" />
                </b-field>
                <b-field label="Email">
                  <b-input v-model="email" size="is-medium" placeholder="Email address" type="email" />
                </b-field>
                <b-field label="UPN">
                  <b-input
                    v-model="upn"
                    name="upn"
                    size="is-medium"
                    placeholder="Pupil UPN"
                    type="text"
                    pattern="[A-Z]{1}\d{12}"
                  />
                </b-field>
                <b-field label="Password">
                  <b-input v-model="password" size="is-medium" placeholder="Password (Minimum 6 characters)" type="password" pattern=".{6,}" />
                </b-field>
              </div>
            </form>
            <div v-if="modalResponse != ''" style="margin: 10px 0" class="alert alert-success">
              {{ modalResponse }}
            </div>
          </div>
          <div id="upload">
            <h4><a href="https://files.edshed.com/docs/pupildata.csv" target="_blank">Download sample CSV file.</a></h4>
            <p>The only required columns are either "name" OR "forename" and "surname". If other columns are present their data will be used.</p>
            <p v-if="group">
              {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }} imported will only be assigned to this group
            </p>
            <p v-else>
              Automatically assign {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }} to a group using a "reg" or "group" column.
            </p>
            <p>Upload existing a {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}' details with UPNs to update details including registration groups</p>
            <p><b>Usernames, UPNs and emails must be unique and passwords must be 6 characters or more.</b></p>
            <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-success="updateParentFromUpload" />
            <div v-if="uploadResponse != ''" class="alert alert-success">
              {{ uploadResponse }}
            </div>
          </div>
        </div>
        <div id="footerSingle" class="modal-footer">
          <button type="button" class="btn button is-link" @click="beforeAddPupil">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import vue2Dropzone from 'vue2-dropzone'
import config from '@/config'
import utils from '@/utils'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { request } from '@/edshed-common/api'

export default {
  name: 'AddPupils',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: ['group'],
  data (router) {
    return {
      loading: '',
      response: 'Data Loading...',
      modalResponse: '',
      uploadResponse: '',
      pupilData: [],
      filteredPupilData: [],
      searchPhrase: '',
      username: '',
      usernameAvailable: false,
      displayname: '',
      name: '',
      email: '',
      upn: '',
      password: '',
      showExtra: false,
      dropzoneOptions: {
        url: (this.group ? config.serverURI + 'users/me/school/groups/' + this.group + '/pupils/upload' : config.serverURI + 'users/me/school/pupils/upload'),
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { 'X-SPELLINGSHED-TOKEN': this.$store.state.token }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      // get all pupils
      if (this.group) {
        this.showExisting()
        this.loadExisting()
      } else {
        this.showSingle()
      }
    })
  },
  methods: {
    localeInfo () {
      let loc = '🇬🇧 United Kingdom'
      if (this.$store.state.user.school.locale === 'en_AU') {
        loc = '🇦🇺 Australia'
      } else if (this.$store.state.user.school.locale === 'en_IE') {
        loc = '🇮🇪 Ireland'
      } else if (this.$store.state.user.school.locale === 'en_NZ') {
        loc = '🇳🇿 New Zealand'
      } else if (this.$store.state.user.school.locale === 'en_US') {
        loc = '🇺🇸 United States'
      }
      return loc
    },
    checkUsername (e) {
      const username = this.username
      if (username.trim() === '' || username.trim().length < 4 || !utils.isAlphaNumericPunctuated(username.trim())) {
        this.usernameAvailable = false
        return
      }
      request('GET', 'users/exists/' + username, null)
        .then((response) => {
          const data = response.data
          this.usernameAvailable = !data.exists
        })
    },
    close () {
      this.$emit('close')
    },
    updateParentFromUpload (file, resp) {
      console.log(resp)
      if (resp.errors && resp.errors.length > 0) {
        console.log(resp.errors.length)
        let errorString = resp.errors.length + ' pupil records failed.\n'
        for (let i = 0; i < resp.errors.length; i++) {
          errorString += '\n'
          errorString += resp.errors[i].name + ': ' + resp.errors[i].message
        }
        errorString += '\n\nWe recommend recording this information then adding these pupils manually.'
        alert(errorString)
      }
      for (let i = 0; i < resp.pupils.length; i++) {
        if (resp.pupils[i].password && resp.pupils[i].password !== '') {
          if (window.localStorage) {
            window.localStorage.setItem('password:' + resp.pupils[i].username, resp.pupils[i].password)
          }
        }
      }
      // if (this.pupilData[i].id === pupils[j].id) {
      //       this.$set(this.pupilData[i], 'password', pupils[j].password)
      //       if (window.localStorage) {
      //         window.localStorage.setItem('password:' + this.pupilData[i].username, pupils[j].password)
      //       }
      //     }
      this.$emit('pupils-added', resp.pupils)
    },
    pupilHasGroup (pupil) {
      if (!pupil.group_ids) {
        return false
      }
      const ids = pupil.group_ids.split(',')
      if (ids.includes('' + this.group)) {
        return true
      } else {
        return false
      }
    },
    searchDidChange () {
      console.log(this.searchPhrase)
      if (this.searchPhrase !== '') {
        this.filteredPupilData = []
        console.log('l: ' + this.pupilData.length)
        for (let i = 0; i < this.pupilData.length; i++) {
          console.log('l: ' + this.pupilData.length)
          console.log('i: ' + i)
          console.log(this.pupilData[i].name.toLowerCase())
          if (this.pupilData[i].name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
            this.pupilData[i].username.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
            (this.pupilData[i].groups != null && this.pupilData[i].groups.toLowerCase().includes(this.searchPhrase.toLowerCase())) ||
            (this.pupilData[i].upn && this.pupilData[i].upn.toLowerCase().includes(this.searchPhrase.toLowerCase())) ||
            (this.pupilData[i].email && this.pupilData[i].email.toLowerCase().includes(this.searchPhrase.toLowerCase()))) {
            console.log('adding')
            this.filteredPupilData.push(this.pupilData[i])
          }
        }
      } else {
        this.filteredPupilData = this.pupilData
      }
      // this.$forceUpdate
    },
    showExisting () {
      this.clearTabs()
      $('#tabExisting').addClass('is-active')
      $('#addExisting').show()
    },
    showSingle () {
      this.clearTabs()
      $('#tabSingle').addClass('is-active')
      $('#addSingle').show()
      $('#footerSingle').show()
    },
    showUpload () {
      this.clearTabs()
      $('#tabUpload').addClass('is-active')
      $('#upload').show()
    },
    clearTabs () {
      $('#tabExisting').removeClass('is-active')
      $('#tabSingle').removeClass('is-active')
      $('#tabUpload').removeClass('is-active')
      $('#addExisting').hide()
      $('#addSingle').hide()
      $('#upload').hide()
      $('#footerSingle').hide()
      this.searchPhrase = ''
    },
    loadExisting () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/pupils', null)
        .then((response) => {
          const data = response.data
          this.pupilData = data.pupils
          this.searchDidChange()

          if (!this.pupilData || this.pupilData.length === 0) {
            this.response = 'No Data'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    beforeAddPupil () {
      let ok = true
      if (!this.name) {
        ok = false
      }
      if (this.email && !utils.isEmail(this.email)) {
        ok = false
      }

      if (this.upn && !utils.isUPN(this.upn)) {
        ok = false
      }

      if (this.username && !this.usernameAvailable) {
        ok = false
      }

      if (this.password && this.password.length < 6) {
        ok = false
      }

      if (ok) {
        this.addPupil()
      }
    },
    addPupil () {
      console.log('add')
      const { name, username, displayname, email, password, upn } = this

      $('#myModal').removeClass('in')
      $('.modal-backdrop').remove()
      $('body').removeClass('modal-open')

      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')

      const d = { name }

      if (email && email !== '') {
        d.email = email
      }

      if (upn && upn !== '') {
        d.upn = upn
      }

      if (username && username !== '') {
        d.username = username
      }

      if (displayname && displayname !== '') {
        d.displayname = displayname
      }

      if (password && password !== '') {
        d.password = password
      } else {
        d.password = this.generatePassword()
      }
      console.log(d)
      /* Making API call to authenticate a user */
      request('POST', 'users/me/school/pupils', d)
        .then((response) => {
          this.toggleLoading()

          window.console.log('Response', response)
          const data = response.data
          if (data.error) {
            alert(data.error)
          } else {
            data.pupils.password = d.password

            if (!data.pupils || data.pupils.length === 0) {
              this.modalResponse = 'No Sata'
            } else {
              this.modalResponse = 'Success!'
              if (this.group) {
                console.log('Adding To Group')
                this.addPupilToGroup(data.pupils)
              }
              this.$emit('pupils-added', data.pupils)
            }

            if (window.localStorage) {
              console.log('setting: password:' + data.pupils.username + ' to ' + data.pupils.password)
              window.localStorage.setItem('password:' + data.pupils.username, data.pupils.password)
            }

            this.name = ''
            this.username = ''
            this.email = ''
            this.password = ''
            const that = this
            setTimeout(() => {
              that.modalResponse = ''
            }, 3000)
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          alert(error.message)
          // this.response = 'Details incorrect'
        })
    },
    addExistingToGroup (data) {
      // loop through pupils and find data.id then insert groupID
      for (let i = 0; i < this.pupilData.length; i++) {
        if (this.pupilData[i].id === data.id) {
          const idsraw = this.pupilData[i].group_ids
          let ids = []
          if (idsraw !== null) {
            ids = idsraw.split(',')
          }
          ids.push('' + this.group)
          this.pupilData[i].group_ids = ids.join(',')
        }
      }
      this.$emit('pupils-added', data)
      this.doAssignToGroup(this.group, [data.id])
    },
    addPupilToGroup (data) {
      console.log('pupil data: ' + data)
      this.doAssignToGroup(this.group, [data.id])
    },
    doAssignToGroup (groupId, pupils) {
      if (pupils.length === 0) {
        alert('No records selected')
        return
      }
      request('POST', 'users/me/school/groups/' + groupId + '/pupils', { pupils: pupils.join() })
        .then((response) => {
          //           var data = response.data

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    generatePassword () {
      return utils.generatePassword()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    toggleExtraAddPupil () {
      this.showExtra = !this.showExtra
    }
  }
}
</script>
<style scoped>
  .tabs {
    padding: 10px;
  }

  #optionalPanel {
    padding-top: 30px;
  }
</style>
